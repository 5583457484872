import React from 'react';

interface Props {
  id: string;
  color: string;
  children: React.ReactElement | React.ReactElement[];
}

export default function Solid(props: Props) {
  return (
    <div
      id={props.id}
      style={{
        display: 'table',
        width: '100%',
        position: 'relative',
      }}
    >
      <div
        style={{
          height: '80vh',
          width: '100%',
          display: 'table-cell',
          position: 'relative',
          zIndex: 1,
          verticalAlign: 'middle',
          textAlign: 'center',
          backgroundColor: props.color,
        }}
      >
        {props.children}
      </div>
    </div>
  );
}
