import React from 'react';
import Image, { StaticImageData } from 'next/image';

interface Props {
  id: string;
  backgroundColor: string;
  textColor: string;
  image: StaticImageData;
  imageAlt?: string;
  children: React.ReactElement | React.ReactElement[];
}

export default function BackGroundImageSection(props: Props) {
  return (
    <div
      id={props.id}
      style={{
        width: '100%',
        position: 'relative',
        backgroundColor: props.backgroundColor,
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '80vh',
          color: props.textColor,
        }}
      >
        <Image
          src={props.image}
          alt={props.imageAlt || ''}
          style={{
            objectFit: 'cover',
            position: 'fixed',
            top: '0',
            width: '100%',
            height: '100%',
            opacity: '0.3',
            minHeight: 500,
          }}
        />
        <div
          style={{
            position: 'relative',
            textAlign: 'center',
            paddingTop: 75,
          }}
        >
          {props.children}
        </div>
      </div>
    </div>
  );
}
