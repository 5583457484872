import { ajax } from '@fosterburg/common-ui/utils/ajax';
import { prefix } from './prefix';

export async function getEvents(currentOnly = true) {
  return await ajax({
    url: prefix + '/events',
    data: {
      currentOnly: currentOnly,
    },
  });
}
