import React from 'react';
import PlaceIcon from '@mui/icons-material/Place';
import ScheduleIcon from '@mui/icons-material/Schedule';
import PhoneIcon from '@mui/icons-material/Phone';
import { Link, Typography } from '@mui/material';

export default function WhereWhenInfo() {
  return (
    <table style={{ textAlign: 'left', margin: 'auto', marginTop: 30, borderSpacing: '0 10px' }}>
      <tbody>
        <tr>
          <td style={{ paddingBottom: 40, paddingRight: 30 }}>
            <PlaceIcon fontSize="large" />
          </td>
          <td style={{ paddingBottom: 40 }}>
            <Link
              href="https://www.google.com/maps/place/Fosterburg+Baptist+Church/@38.968558,-90.073349,16.59z/data=!4m6!3m5!1s0x87df5de0722f7ec7:0xd598fbe7d87860ac!8m2!3d38.9692163!4d-90.0747816!16s%2Fg%2F1tcyscml"
              target="_blank"
              rel="noreferrer"
              underline="hover"
            >
              <Typography variant="h6">4737 Fosterburg Rd</Typography>
              <Typography variant="h6">Bethalto, IL 62010</Typography>
            </Link>
          </td>
        </tr>
        <tr>
          <td style={{ paddingBottom: 40, paddingRight: 30 }}>
            <ScheduleIcon fontSize="large" />
          </td>
          <td style={{ paddingBottom: 40 }}>
            <div>
              <Typography variant="h6">Sunday</Typography>
              <hr style={{ margin: 4 }} />
              <table style={{ width: '100%' }}>
                <tbody>
                  <tr>
                    <td>
                      <Typography variant="body2">Sunday School</Typography>
                    </td>
                    <td style={{ textAlign: 'right' }}>
                      <Typography variant="body2">9 am</Typography>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Typography variant="body2">Morning Worship</Typography>
                    </td>
                    <td style={{ textAlign: 'right' }}>
                      <Typography variant="body2">10 am</Typography>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Typography variant="body2">Evening Worship</Typography>
                    </td>
                    <td style={{ textAlign: 'right' }}>
                      <Typography variant="body2">6:30 pm</Typography>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div style={{ marginTop: 15 }}>
              <Typography variant="h6">Wednesday</Typography>
              <hr style={{ margin: 4 }} />
              <table style={{ width: '100%' }}>
                <tbody>
                  <tr>
                    <td>
                      <Typography variant="body2">AWANA</Typography>
                    </td>
                    <td style={{ textAlign: 'right' }}>
                      <Typography variant="body2">6:30 pm</Typography>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Typography variant="body2">Youth Group</Typography>
                    </td>
                    <td style={{ textAlign: 'right' }}>
                      <Typography variant="body2">6:30 pm</Typography>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Typography variant="body2">Prayer Meeting</Typography>
                    </td>
                    <td style={{ textAlign: 'right' }}>
                      <Typography variant="body2">6:30 pm</Typography>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </td>
        </tr>
        <tr>
          <td style={{ paddingBottom: 40, paddingRight: 30 }}>
            <PhoneIcon fontSize="large" />
          </td>
          <td style={{ paddingBottom: 40 }}>
            <Link href="tel:6182592550" underline="hover">
              <Typography variant="h6">(618) 259-2550</Typography>
            </Link>
          </td>
        </tr>
      </tbody>
    </table>
  );
}
