import { Box, Card, CardActionArea, CardContent, Typography } from '@mui/material';

interface Props {
  title: string;
  date: Date;
  description?: string;
  href: string | null;
}

function EventCard(props: Props) {
  return (
    <Card>
      <CardActionArea href={props.href} disabled={!props.href}>
        <Box sx={{ display: 'flex' }}>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <CardContent sx={{ flex: '1 0 auto', textAlign: 'center' }}>
              <Typography component="div" variant="subtitle1">
                {props.date.toLocaleString('default', { month: 'short' })}
              </Typography>
              <Typography component="div" variant="h5">
                {props.date.toLocaleString('default', { day: '2-digit' })}
              </Typography>
            </CardContent>
            <Box sx={{ display: 'flex', alignItems: 'center', pl: 1, pb: 1 }}></Box>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <CardContent sx={{ flex: '1 0 auto' }}>
              <Typography component="div" variant="h5">
                {props.title}
              </Typography>
              <Typography variant="subtitle1" color="text.secondary" component="div">
                {props.date.toLocaleString('default', { weekday: 'short' })} {props.date.toLocaleTimeString('en-us', { hour: 'numeric', minute: '2-digit' })}
              </Typography>
              <Typography component="div" variant="body2">
                {props.description}
              </Typography>
            </CardContent>
          </Box>
        </Box>
      </CardActionArea>
    </Card>
  );
}

export default EventCard;
